import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { ChartData, ChartOptions } from 'chart.js/auto';
import { BdiScore } from '../../types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const labelMappingFull: Record<string, string> = {
    ability_to_make_decisions: 'Ability to Make Decisions',
    appetite: 'Loss of Appetite',
    crying_frequency: 'Crying Frequency',
    enjoyment_and_satisfaction: 'Lack of Enjoyment and Satisfaction',
    feeling_of_sadness: 'Feelings of Sadness',
    feelings_of_failure: 'Feelings of Failure',
    feelings_of_guilt: 'Feelings of Guilt',
    feelings_of_punishment: 'Feelings of Punishment',
    feelings_of_tiredness: 'Feelings of Tiredness',
    frequency_of_irritability: 'Frequency of Irritability',
    interest_in_physical_intimacy: 'Interest in Physical Intimacy',
    loss_of_interest: 'Loss of Interest',
    physical_symptoms: 'Physical Symptoms',
    self_criticism: 'Self Criticism',
    self_dislike: 'Self Dislike',
    sleep_habits: 'Sleep Habits',
    thoughts_about_appearance: 'Thoughts About Appearance',
    thoughts_about_future: 'Thoughts About Future',
    thoughts_about_productivity: 'Thoughts About Productivity',
    thoughts_of_suicide: 'Thoughts of Suicide',
    worry_about_physical_health: 'Worry About Physical Health',
};


const labelMapping: Record<string, string> = {
    ability_to_make_decisions: 'Decisions',
    appetite: 'Appetite',
    crying_frequency: 'Crying',
    enjoyment_and_satisfaction: 'Enjoyment',
    feeling_of_sadness: 'Sadness',
    feelings_of_failure: 'Failure',
    feelings_of_guilt: 'Guilt',
    feelings_of_punishment: 'Punishment',
    feelings_of_tiredness: 'Tiredness',
    frequency_of_irritability: 'Irritability',
    interest_in_physical_intimacy: 'Intimacy',
    loss_of_interest: 'Interest',
    physical_symptoms: 'Symptoms',
    self_criticism: 'Criticism',
    self_dislike: 'Dislike',
    sleep_habits: 'Sleep',
    thoughts_about_appearance: 'Appearance',
    thoughts_about_future: 'Future',
    thoughts_about_productivity: 'Productivity',
    thoughts_of_suicide: 'Suicide',
    worry_about_physical_health: 'Health',
};



// Define color array for bars
const barColors = [
    'rgba(255, 99, 132, 0.6)',
    'rgba(54, 162, 235, 0.6)',
    'rgba(255, 206, 86, 0.6)',
    'rgba(75, 192, 192, 0.6)',
    'rgba(153, 102, 255, 0.6)',
    'rgba(255, 159, 64, 0.6)',
    'rgba(199, 199, 199, 0.6)',
    'rgba(83, 102, 255, 0.6)',
    'rgba(201, 203, 207, 0.6)',
    'rgba(144, 238, 144, 0.6)',
    'rgba(255, 69, 0, 0.6)',
    'rgba(70, 130, 180, 0.6)',
    'rgba(238, 130, 238, 0.6)',
    'rgba(240, 128, 128, 0.6)',
    'rgba(46, 139, 87, 0.6)',
    'rgba(250, 128, 114, 0.6)',
    'rgba(100, 149, 237, 0.6)',
    'rgba(255, 215, 0, 0.6)',
    'rgba(0, 191, 255, 0.6)',
    'rgba(65, 105, 225, 0.6)',
];

// Define the order of the BDI questionnaire
const bdiOrder = [
    'feeling_of_sadness',
    'ability_to_make_decisions',
    'appetite',
    'crying_frequency',
    'enjoyment_and_satisfaction',
    'feelings_of_failure',
    'feelings_of_guilt',
    'feelings_of_punishment',
    'feelings_of_tiredness',
    'frequency_of_irritability',
    'interest_in_physical_intimacy',
    'loss_of_interest',
    'physical_symptoms',
    'self_criticism',
    'self_dislike',
    'sleep_habits',
    'thoughts_about_appearance',
    'thoughts_about_future',
    'thoughts_about_productivity',
    'thoughts_of_suicide',
    'worry_about_physical_health',
];


// Chart component
const BdiChart: React.FC<{ data?: BdiScore }> = ({ data }) => {
    // Extract the keys (labels) and values (data points)
    if (!data) {
        const emptyChartData: ChartData<'bar', number[]> = {
            labels: [],
            datasets: [
                {
                    label: 'BDI Score',
                    data: [],
                    backgroundColor: [],
                },
            ],
        };

        const emptyOptions: ChartOptions<'bar'> = {
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                title: {
                    display: false,
                },
            },
            scales: {
                x: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                },

                y: {
                    ticks: {
                        display: false,
                    },
                    grid: {
                        display: false,
                    },
                },
            },
        };

        return <Bar data={emptyChartData} options={emptyOptions} height={120} />;
    }
    const labels = bdiOrder.map((key) => labelMapping[key]);
    //@ts-ignore
    const values = bdiOrder.map((key) => data[key] || 0);

    const readableLabels = bdiOrder.map((label) => labelMapping[label] || label);
    const fullLabels = bdiOrder.map((label) => labelMappingFull[label] || label);

    // Chart data structure
    const chartData: ChartData<'bar', number[]> = {
        labels: readableLabels,
        datasets: [
            {
                label: 'BDI Score',
                data: values,
                backgroundColor: barColors.slice(0, labels.length),
                // borderColor: barColors.slice(0, labels.length).map((color) => color.replace('0.6', '1')),
                // borderWidth: 1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false, // Hiding the dataset legend since we will handle it via labels
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => {
                        const label = fullLabels[tooltipItem.dataIndex];
                        const value = tooltipItem.raw;
                        return ` ${label}  ${value}`;
                    },
                }
            },
        },

        scales: {
            x: {
                ticks: {
                    maxRotation: 0,  // Disable rotation
                    minRotation: 0,  // Ensure no angle is applied
                    autoSkip: false, // Prevent skipping of labels
                    padding: 10, // Add padding to avoid overlapping
                    font: {
                        size: 10, // Reduce font size to fit more labels
                    },
                },
                grid: {
                    display: false, // Hide grid lines for x-axis
                },
            },
            y: {
                beginAtZero: true,
                max: 3, // Adjust this based on the max score range
                grid: {
                    display: true, // Hide grid lines for x-axis
                },
            },

        },
        barThickness: 35,
        elements: {
            bar: {
                borderRadius: 15, // Add border radius to bars
            },
        },
    };

    return <Bar data={chartData} options={options} height={120} />;
};

export default BdiChart;