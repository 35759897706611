import { Box, Card, CardContent, Typography } from '@mui/material';
import { motion, MotionValue } from 'framer-motion';
import landingpageStyle from "../style/section-one.module.css";

const SectionOne = () => {
    return (
        <div className={landingpageStyle.section_one}>
            <motion.p className={landingpageStyle.introtext}>
                <span className={landingpageStyle.fade_in_initial}>Provide the best care to your clients 🦋</span>
            </motion.p>
            <br />
            <div className={landingpageStyle.fade_in_subtitle}>
                <Typography variant="h5" color="text.secondary">Empowering clinicians with advanced tools for <span className="bold">exceptional mental health care</span>
                </Typography>
            </div>
        </div>);
}

export default SectionOne;