import { Button, Typography } from "@mui/material"
import addExtension from "../../assets/addExtension.png"
import pinExtension from "../../assets/pinExtension.png"
import transcriberPermission from "../../assets/transcriberSubscription.png"
import transcriberStyle from "./style/transcriber.module.css"
import { ScrollRestoration } from "react-router-dom"
import { FAQ } from "../../components/faq"

const faqData = [
    {
        title: "Why is my 'Start Recording' button not active?",
        description: "The button activates only when you are in a Google Meet meeting."
    },
    {
        title: "Are my notes secure?",
        description: "Yes! We take security very seriously and ensure that your notes are stored securely. We ensure that your notes can not be accessed by anyone apart from you unless you decide to share it."
    },
    {
        title: "What should I do if the transcription is inaccurate?",
        description: "If the transcription is inaccurate, you can manually edit the notes. We are continually improving the transcription accuracy and appreciate feedback on any issues encountered."
    },
    {
        title: "Is there a limit to how many clients I can manage?",
        description: "There is no strict limit to the number of clients you can manage within the extension, allowing you to maintain comprehensive records for all your clients."
    },
    {
        title: "Can I edit the generated notes?",
        description: "Yes, after the session is transcribed and the notes are generated, you can review and edit them directly within the extension before saving them to the client's record."
    },
    {
        title: "How are the notes organized?",
        description: "The notes are automatically organized by client. Each client has a separate record where all the therapy notes related to them are stored, making it easy to review and manage client histories."
    },
];


export const Transcriber = ({ }) => {
    return <div className={transcriberStyle.container}>
        <ScrollRestoration />
        <Typography variant="h3" fontWeight="bold">Hi There!</Typography>
        <Typography variant="h6" fontWeight="normal" color={"grey"} >🦋 Thank you for choosing Serene-Transcriber. Let's get started!</Typography>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">🤔 Is it for me?</Typography>
            <br />
            <Typography variant="h6" fontWeight="normal">✅ Are you a <strong>mental health clinician</strong> who wants to automatically generate therapy notes from your online sessions?</Typography>
            <Typography variant="h6" fontWeight="normal">✅ Do you want to store your notes in a <strong>safe secure bucket</strong> where they will never get lost?</Typography>
            <Typography variant="h6" fontWeight="normal">✅ Do you like looking for <strong>deeper insights</strong> such as <strong>sentiment analysis</strong>, <strong>tone analysis</strong> and more to uncover subtle data about your clients?</Typography>
            <br />
            <br />
            <Typography variant="h5" fontWeight="bold">Then YES! it is for you!</Typography>
        </div>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">✨ Create Your Free Account</Typography>
            <br />
            <Typography variant="h6" fontWeight="normal">To get started, you should have a Vybz account. Creating an account is completely <strong>free</strong> and takes  <strong>less than a minute!</strong></Typography>
            <Typography variant="h6" fontWeight="normal">If you already have an account, let's go the next step.</Typography>
            <br />
            <Button onClick={() => { window.open('/login', '_blank')?.focus() }} variant="outlined" size="large" sx={{ borderRadius: 30, }} >Create Account</Button>
        </div>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">🛠️ Add The Browser Extension</Typography>
            <img src={addExtension} className={transcriberStyle.add_extension_image} />
            <Typography variant="h6" fontWeight="normal">The transcriber browser extension allows you to record and transcribe your Google Meet meetings. It will automatically generate detailed <strong>SOAP</strong> and <strong>BIRP</strong> reports, provide detailed emotional analysis and much more.</Typography>
            <br />
            <Button onClick={() => { window.open('https://chromewebstore.google.com/detail/serene-ai-notetaker-for-m/nibecbnhnciangjglhlfeecnodoedpdk', '_blank')?.focus() }} variant="outlined" size="large" sx={{ borderRadius: 30, }} >Add Extension</Button>
        </div>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">📌 Pin Serene (Optional)</Typography>
            <div className={transcriberStyle.pin_content}>
                <img src={pinExtension} className={transcriberStyle.pin_extension_image} />
                <div>
                    <Typography variant="h6" fontWeight="normal">Quickly Access Serene Transcriber by pinning it to your browser. Tap on the extension button on the <strong>top right</strong> section of your browser.</Typography>
                    <Typography variant="h6" fontWeight="normal">Find Serene from the list of extensions and pin it for easy access during your meetings!</Typography>
                </div>
            </div>
        </div>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">🔑 Grant Permissions</Typography>
            <br />
            <Typography variant="h6" fontWeight="normal">You would be prompted to provide microphone permission when you launch the extension for the first time.</Typography>
            <Typography variant="h6" fontWeight="normal">This permission ensures that <strong>your voice is recorded</strong> during the meeting.</Typography>
            <div className={transcriberStyle.permission_image_container}>
                <img src={transcriberPermission} className={transcriberStyle.permission_image} />
            </div>
        </div>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">⏺️ Start Recording</Typography>
            <br />
            <Typography variant="h6" fontWeight="normal">The "Start Recording" button would be active when a valid Google Meet meeting is detected. Tap on the button to start recording your meeting. Once your meeting ends, either press on "Stop Recording" or simply close the tab. </Typography>
            <br />
            <Typography variant="h6" fontWeight="normal"><strong>That's it!</strong> Your meeting notes would be available to you <strong>within 20 mins</strong> (based on the duration of your meeting).</Typography>
            <br />
            <Button onClick={() => { window.open('/dashboard', '_blank')?.focus() }} variant="outlined" size="large" sx={{ borderRadius: 30, }} >Check Your Notes</Button>
        </div>
        <br />
        <br />
        <div className={transcriberStyle.step_container}>
            <Typography variant="h4" fontWeight="bold">📑 Manage Your Documents</Typography>
            <br />
            <Typography variant="h6" fontWeight="normal">You can access all your notes from your dashboard. <strong>Keep track</strong> of all your client records in one secure place</Typography>
        </div>
        <br />
        <br />
        <Typography variant="h4" fontWeight="bold">❓ Frequently Asked Questions</Typography>
        <br />
        <br />
        <FAQ data={faqData} />
    </div >

}