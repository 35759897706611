import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import landingpageStyle from "../style/section-five.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SocialIcon } from 'react-social-icons'
import { getFirestore, collection, addDoc } from "firebase/firestore";


export const SectionFive = () => {

    const navigate = useNavigate();


    return (
        <div className={landingpageStyle.fifth_section}>

            <div className={landingpageStyle.fifth_section_title}>And the best part? 🌟</div>
            <div className={landingpageStyle.fifth_section_subtitle}>Get started for <strong>FREE!</strong> Signing up takes a few seconds and you will have aacess to all the tools immediately.</div>

            <div className={landingpageStyle.button_row}>
                <div className={landingpageStyle.actions}>
                    <div className={landingpageStyle.button_container}>
                        <Button onClick={() => { navigate("/login") }} variant="outlined" size="large" sx={{ borderRadius: 30, height: 60, width: 250, }}>Join Today</Button>
                    </div>

                    <div className={landingpageStyle.actions_items_container}>
                        <div className={landingpageStyle.actions_items} onClick={() => {
                            navigate("/privacy");
                        }}>Privacy Policy</div>
                        <div className={landingpageStyle.actions_items} onClick={() => {
                            ;
                        }}>Contact Us</div>
                    </div>
                    <br></br>
                    <div className={landingpageStyle.social_icons}>
                        <SocialIcon url="https://www.instagram.com/vybzhealth/" />
                        <SocialIcon url="https://www.linkedin.com/company/104071148" />
                    </div>
                </div>


            </div>


        </div>)
}


const DialogBox = ({ open, handleClose, setEmail, onSubmit }: { open: boolean, handleClose: any, setEmail: any, onSubmit: any }) => <Dialog
    open={open}
    onClose={handleClose}
    PaperProps={{
        component: 'form',
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            const db = getFirestore()
            if (email != "") {
                const docRef = await addDoc(collection(db, "invites"), {
                    email: email
                });
            }
            handleClose();
        },
    }}
>
    <DialogTitle>We would love to have you join us ❤️</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Vybz is currently in <strong>private beta</strong>. Please enter your email below to request access and we will get back to you soon. 💌
        </DialogContentText>
        <TextField
            onChange={(value) => { setEmail(value.target.value) }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard" />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onSubmit={onSubmit} type="submit">Submit</Button>
    </DialogActions>
</Dialog>;