import { Typography } from "@mui/material"
import { Birp } from "../../types"
import noteSection from "./style/note-section.module.css"

export const BirpNotes = ({ birpNotes }: { birpNotes: Birp | undefined }) => {
    return (<div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Behavior</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{birpNotes?.behavior}</Typography>
            </div>
        </div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Intervention</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{birpNotes?.intervention}</Typography>
            </div>
        </div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Response</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{birpNotes?.response}</Typography>
            </div>
        </div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Plan</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{birpNotes?.plan}</Typography>
            </div>
        </div>
    </div>)
}