import React, { ReactElement } from 'react';
import protectedRouteStyle from "./style/protected-route.module.css"
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import logo from "../assets/logo.svg"
import { getFirebaseAuth } from '../const';

interface ProtectedRouteProps {
    children: ReactElement;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const [user, loading] = useAuthState(getFirebaseAuth());
    const location = useLocation();

    if (loading) {
        return <div className={protectedRouteStyle.container}>
            <img className={protectedRouteStyle.logo} src={logo} />
        </div>;
    }

    if (!user) {
        return <Navigate to="/login/" state={{ from: location }} />;
    }

    return children;
};
