import React from 'react';
import styles from './style/privacy.module.css';  // Import the CSS module
import { ScrollRestoration } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div className={styles.privacyPolicy}>
            <ScrollRestoration />
            <h1 className={styles.title}>Privacy Policy</h1>
            <p>Last Updated: June 7, 2024</p>

            <p>Thank you for choosing Vybz. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your data when you use Vybz.</p>

            <div className={styles.tableOfContents}>
                <h2>Table of Contents</h2>
                <a href="#information-we-collect">1. Information We Collect</a>
                <a href="#physiological-data" className={styles.subLink}>1.1 Physiological Data</a>
                <a href="#location-data" className={styles.subLink}>1.2 Location Data</a>
                <a href="#input-data" className={styles.subLink}>1.3 Input Data</a>
                <a href="#user-data" className={styles.subLink}>1.4 User Data</a>
                <a href="#cookies" className={styles.subLink}>1.5 Cookies and Usage Data</a>
                <a href="#how-we-use">2. How We Use Your Information</a>
                <a href="#functionality" className={styles.subLink}>2.1 Functionality of Vybz</a>
                <a href="#personalization" className={styles.subLink}>2.2 Personalization</a>
                <a href="#contacting-user" className={styles.subLink}>2.3 Contacting the User</a>
                <a href="#analytics" className={styles.subLink}>2.4 Analytics and Improvement</a>
                <a href="#user-control">3. User Control and Data Deletion</a>
                <a href="#control-over-data" className={styles.subLink}>3.1 Control Over Your Data</a>
                <a href="#data-deletion" className={styles.subLink}>3.2 Data Deletion</a>
                <a href="#data-sharing">4. Data Sharing</a>
                <a href="#no-external-sharing" className={styles.subLink}>4.1 No External Sharing</a>
                <a href="#security">5. Security</a>
                <a href="#changes">6. Changes to This Privacy Policy</a>
                <a href="#contact">7. Contact Us</a>
            </div>

            <h2 id="information-we-collect" className={styles.sectionTitle}>1. Information We Collect</h2>

            <h3 id="physiological-data" className={styles.subsectionTitle}>1.1 Physiological Data</h3>
            <p className={styles.paragraph}>
                We collect data such as heart rate, respiration rate, and other physiological data from the wearable device or smartphone you use.
                During onboarding, we will explicitly ask for your permission to collect each type of physiological data.
                You can modify the data shared at any later point by managing your account settings. This data helps us monitor your health trends and provide insights into your physical and mental well-being.
            </p>

            <h3 id="location-data" className={styles.subsectionTitle}>1.2 Location Data</h3>
            <p className={styles.paragraph}>
                We collect your location data to provide location-based services. This includes data from your device's GPS, Wi-Fi, or Bluetooth to understand your environment and context better,
                which can be crucial for certain app features such as mood analysis based on location.
            </p>

            <h3 id="input-data" className={styles.subsectionTitle}>1.3 Input Data</h3>
            <p className={styles.paragraph}>
                We collect inputs that you provide as part of the app's functionality, such as journal entries and mood logs. This data helps us personalize your experience and provide relevant recommendations.
            </p>

            <h3 id="user-data" className={styles.subsectionTitle}>1.4 User Data</h3>
            <p className={styles.paragraph}>
                We collect personal information such as your email address and name when you register for and use Vybz. This information is used to create and manage your account, communicate with you, and ensure a secure and personalized experience. We also collect and store any additional
                information that you would choose to share to personalize your profile such as your display picture, height, weight etc.
            </p>

            <h3 id="cookies" className={styles.subsectionTitle}>1.5 Cookies and Usage Data</h3>
            <p className={styles.paragraph}>
                We use cookies and similar tracking technologies to track the activity on our service and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.
                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.
            </p>

            <h2 id="how-we-use" className={styles.sectionTitle}>2. How We Use Your Information</h2>

            <h3 id="functionality" className={styles.subsectionTitle}>2.1 Functionality of Vybz</h3>
            <p className={styles.paragraph}>To operate and maintain the all the functionalities of Vybz, ensuring it operates correctly and provides the services you expect. This also includes troubleshooting, data analysis, testing, and research.</p>

            <h3 id="personalization" className={styles.subsectionTitle}>2.2 Personalization</h3>
            <p className={styles.paragraph}>To personalize your experience with Vybz, including providing recommendations and insights based on your data. For example, we may suggest activities or exercises tailored to your current mood or physiological state.</p>

            <h3 id="contacting-user" className={styles.subsectionTitle}>2.3 Contacting the User</h3>
            <p className={styles.paragraph}>To send you updates, notifications, and other information related to your use of Vybz. This may include reminders to log your mood, updates on new features, or responses to your support requests.</p>

            <h3 id="analytics" className={styles.subsectionTitle}>2.4 Analytics and Improvement</h3>
            <p className={styles.paragraph}>To analyze how you use Vybz, enabling us to improve our services and develop new features. We use analytics to understand user behavior and preferences, which helps us make informed decisions about the app's development.</p>

            <h2 id="user-control" className={styles.sectionTitle}>3. User Control and Data Deletion</h2>

            <h3 id="control-over-data" className={styles.subsectionTitle}>3.1 Control Over Your Data</h3>
            <p className={styles.paragraph}>You have control over what information you choose to share with Vybz. You can modify or delete your data at any time through the app settings. We provide tools to help you manage your data and privacy preferences.</p>

            <h3 id="data-deletion" className={styles.subsectionTitle}>3.2 Data Deletion</h3>
            <p className={styles.paragraph}>If you choose to delete your account, your data will be permanently deleted from our systems within approximately 48 hours. This process ensures that your information is completely removed from our active databases and backups.</p>

            <h2 id="data-sharing" className={styles.sectionTitle}>4. Data Sharing</h2>

            <h3 id="no-external-sharing" className={styles.subsectionTitle}>4.1 No External Sharing</h3>
            <p className={styles.paragraph}>We do not share your personal data outside of the Vybz platform, except as necessary to comply with legal obligations or protect our rights. Your data is stored securely and is only accessible by authorized personnel.</p>

            <h2 id="security" className={styles.sectionTitle}>5. Security</h2>
            <p className={styles.paragraph}>We take appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. Our security practices are regularly reviewed and updated to ensure your data remains safe.</p>

            <h2 id="changes" className={styles.sectionTitle}>6. Changes to This Privacy Policy</h2>
            <p className={styles.paragraph}>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Significant changes will be communicated through the app or via email.</p>

            <h2 id="contact" className={styles.sectionTitle}>7. Contact Us</h2>
            <p className={styles.paragraph}>If you have any questions about this Privacy Policy, please contact us at <strong>info@vybz.health</strong>.</p>
        </div>
    );
}

export default PrivacyPolicy;
