import { createSlice } from '@reduxjs/toolkit'

/**
 * Vybz user data
 */
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        fetched: false,
        id: "",
        display_name: "",
    },
    reducers: {
        updateUser: (state, action) => {
            state.fetched = true;
            state.display_name = action.payload["display_name"] ?? ""
            state.id = action.payload["id"] ?? ""
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateUser, } = userSlice.actions

export default userSlice.reducer

