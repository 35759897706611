import { getAuth, User } from 'firebase/auth'
import { useState, useEffect } from 'react'

export function useAuth() {
    const [authState, setAuthState] = useState<{
        user: User | null;
        isSignedIn: boolean,
        pending: boolean,
    }>({
        isSignedIn: false,
        pending: true,
        user: null,
    })

    const auth = getAuth()

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged(user =>
            setAuthState({ user, pending: false, isSignedIn: !!user })
        )
        return () => unregisterAuthObserver()
    }, [])

    return { auth, ...authState }
}