import { DashboardCard } from "../dashboard-card";
import notesImg from "../../../assets/note_book.png"
import calenderImg from "../../../assets/calender.png"
import homeStyle from "../style/home.module.css"
import { CircularProgress, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { CALENDLY_CLIENT_ID } from "../../../const";
import { connectCalender } from "../../../helper/calendly-helper";

// Last note was generated on 12th Sep 6pm
// 
export const Home = ({
    onViewNotes,
    onShowCalender,
    notesLoading,
    lastNote,
    pendingLoading,
    actionItems,
    inProgress,
    calenderConnected,
    upcomingEvents,
    calenderLoading
}: {
    onViewNotes: any,
    onShowCalender: any
    notesLoading: boolean,
    lastNote: any,
    pendingLoading: boolean,
    actionItems: number,
    inProgress: number,
    calenderConnected: boolean,
    upcomingEvents: Array<any>,
    calenderLoading: boolean
}) => {


    let subtitle = ""

    if (!notesLoading && lastNote != null) {
        const noteTimestamp = lastNote.timestamp
        const time = moment(noteTimestamp).format("MMM Do")
        subtitle = `Last note generated on ${time}`
    } else {
        subtitle = "No notes generated yet"
    }

    return (<div className={homeStyle.container}>
        <Typography variant="h4">At A Glance</Typography>
        <Typography variant="body2" color={"grey"} fontSize={14}>Your priority list, simplified</Typography>
        <br />
        <div className={homeStyle.row_1}>
            {/* the note card covers details about the last note that was generated*/}
            <DashboardCard title="My Notes" subtitle={subtitle} image={notesImg}
                actionTitle={"View"}
                onActionCLick={onViewNotes}
                loading={notesLoading}>
                <div className={homeStyle.inner_container}>
                    {(notesLoading || pendingLoading) ? <div className={homeStyle.loading_container}><CircularProgress size={20} /></div> : <div>
                        <Typography variant="body2" fontSize={12}>{actionItems == 0 ? "👍 You are all caught up!" : `✒️ ${actionItems} action ${actionItems == 1 ? "item" : "items"} pending`}</Typography>
                        <Typography variant="body2" fontSize={12}>{`⚙️ ${inProgress} ${inProgress == 1 ? "transcription" : "transcriptions"} in progress`}</Typography>
                    </div>}
                </div>

            </DashboardCard>
            {/* <DashboardCard title="My Sessions" subtitle={!calenderConnected ? "Connect your calender" : "Upcoming sessions"} image={calenderImg}
                actionTitle={!calenderConnected ? "Connect" : "Show"}
                onActionCLick={calenderConnected ? onShowCalender : connectCalender}
                loading={notesLoading}>
                <div className={homeStyle.inner_container}>
                    {calenderLoading ? <div className={homeStyle.loading_container}><CircularProgress size={20} /></div> : calenderConnected ? upcomingEvents.length > 0 ? <UpComingEvents event={upcomingEvents[0]} />
                        : <Typography variant="body2" fontSize={12}>🕐 You do not have any upcoming session</Typography> : (<div>
                            <Typography variant="body2" fontSize={12}>Connect or create a <Tooltip title="Calendly is a scheduling platform. Connect your existing account or create a new one to manage your schedule directly from this dashboard" placement="top" arrow>
                                <span className={homeStyle.tooltip_highlight}>calendly</span>
                            </Tooltip> account</Typography>
                        </div>)}
                </div>
            </DashboardCard> */}
        </div >
    </div >);
}

/**
 * 
 * @param event 
 * @returns 
 */
const UpComingEvents = ({ event }: { event: any }) => {
    const eventStart = event["start_time"]
    const difference = formatTimeDifference(eventStart)
    return (<div><Typography variant="body2" fontSize={12}>🕐 You have a session coming up in <strong>{difference}</strong></Typography ></div>)
}


/**
 * 
 * @param date 
 * @returns 
 */
const formatTimeDifference = (date: string) => {
    const now = moment();
    const givenDate = moment(date);
    const duration = moment.duration(givenDate.diff(now));

    if (duration.asYears() >= 1) {
        const years = Math.floor(duration.asYears());
        const months = Math.floor(duration.subtract(moment.duration(years, 'years')).asMonths());
        return `${years} year${years > 1 ? 's' : ''} and ${months} month${months > 1 ? 's' : ''}`;
    } else if (duration.asMonths() >= 1) {
        const months = Math.floor(duration.asMonths());
        const days = Math.floor(duration.subtract(moment.duration(months, 'months')).asDays());
        return `${months} month${months > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''}`;
    } else if (duration.asWeeks() >= 1) {
        const weeks = Math.floor(duration.asWeeks());
        const days = Math.floor(duration.subtract(moment.duration(weeks, 'weeks')).asDays());
        return `${weeks} week${weeks > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''}`;
    } else if (duration.asDays() >= 1) {
        const days = Math.floor(duration.asDays());
        const hours = Math.floor(duration.subtract(moment.duration(days, 'days')).asHours());
        return `${days} day${days > 1 ? 's' : ''} and ${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
        const hours = Math.floor(duration.asHours());
        const minutes = Math.floor(duration.subtract(moment.duration(hours, 'hours')).asMinutes());
        return `${hours} hour${hours > 1 ? 's' : ''} and ${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
}

