import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type FAQItem = {
    title: string;
    description: string;
};

type FAQProps = {
    data: FAQItem[];
};

export const FAQ: React.FC<FAQProps> = ({ data }) => {
    return (
        <div>
            {data.map((item, index) => (
                <div>
                    <Accordion key={index} sx={{ background: "transparent", borderBottom: "0px", boxShadow: "none" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                        >
                            <Typography variant='h6'>{item.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant='h6' fontWeight="normal">
                                {item.description}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))}
        </div>
    );
};