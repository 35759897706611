import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Routes
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import LandingPage from './pages/landing';
import PrivacyPolicy from './pages/privacy';

// Import the functions you need from the SDKs you need
import Stress from './pages/stress';
import Serene from './pages/serene';
import Login from './pages/login';
import { SereneDashboard } from './pages/serene/dashboard';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { USER_COLLECTION, getFirebaseAuth, getFirebaseFirestore } from './const';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { updateUser } from './slice/user-slice';
import { updateUser as sereneUserUpdate } from './slice/serene-user';
import { NoteEdit } from './pages/note-edit';
import { ClientHistory } from './pages/client-history';
import { Transcriber } from './pages/serene/transcriber';
import { CalendlyCallback } from './pages/callback/calendly-callback';
import { AppState } from './types';
import { getCalenderData, refreshAccessToken } from './helper/calendly-helper';
import { updateCalenderData } from './slice/calender-slice';
import { SereneProfile } from './pages/serene-profile';
import moment from 'moment';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Function to determine the current subdomain
const getSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  return subdomain;
};

/**
 * Create the different routes
 * @returns 
 */
const RootRouter = () => {
  const subdomain = getSubdomain();

  if (subdomain != "serene") {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/stress" element={<Stress />} />
        <Route path="/building" element={<div>Page Under Construction!!</div>} />
        <Route path="/login/" element={<Login loginType='app' />} />
      </Routes>
    );
  } else if (subdomain === "serene") {
    return (
      <Routes>
        <Route path="/login/" element={<Login loginType='serene' />} />
        <Route path="/serene-transcriber/" element={<Transcriber />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/" element={<Serene />} />
        <Route path="/dashboard" element={<SereneDashboard />} />
        <Route path="/note/:id" element={<NoteEdit />} />
        <Route path="/calendly-callback" element={<CalendlyCallback />} />
        <Route path="/client-history/:clientid" element={<ClientHistory />} />
        <Route path="/profile" element={<SereneProfile />} />
      </Routes>
    );
  }
};




//const analytics = getAnalytics(app);
const routes = createRoutesFromElements(
  //@ts-ignore
  <Route path="/*" element={<RootRouter />} />
);

const router = createBrowserRouter(routes);

const App = () => {

  const [user, loadingAuth, error] = useAuthState(getFirebaseAuth());
  const [calenderFetched, setCalenderFetched] = useState<string | null>(null);
  const sereneUser = useSelector((state: AppState) => state.sereneUser)
  const dispatch = useDispatch()



  // fetch calender details once the user is updated 
  useEffect(() => {
    // const fetchCalenderDetails = async () => {
    //   setCalenderFetched("started")
    //   const accessToken = await refreshAccessToken(sereneUser.calenderRefreshToken, sereneUser.id)
    //   const data = await getCalenderData(accessToken.access)
    //   dispatch(updateCalenderData(data))
    //   setCalenderFetched("complete")
    // }

    if (sereneUser.fetched && !calenderFetched) {
      const isCalnederConnected = sereneUser.calenderConnected
      if (isCalnederConnected) {
        // fetchCalenderDetails();
      } else {
        dispatch(updateCalenderData({}))
        setCalenderFetched("complete")
      }
    }
  }, [sereneUser])


  useEffect(() => {
    // set up the listeners here
    if (user) {
      const unsubscribeUser = setUpUserListener(user.uid, dispatch);
      const unsubscribeSereneUser = setUpSereneUserListener(user.uid, dispatch)
      return () => {
        unsubscribeUser()
        unsubscribeSereneUser()
      }
    }
  }, [user])

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

const setUpUserListener = (uid: string, dispatch: any) => {

  const userDocRef = doc(getFirebaseFirestore(), USER_COLLECTION, uid);

  // Set up the listener for real-time updates
  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      dispatch(updateUser(docSnapshot.data()));
    } else {
      dispatch(updateUser({ display_name: "", id: uid }));
    }
  }, (error) => {
    console.log("Error fetching document: ", error);
  });

  return unsubscribe;
}

/**
 * 
 * @param uid the user id
 * @param dispatch the store dispatch function
 * @returns 
 */
const setUpSereneUserListener = (uid: string, dispatch: any) => {

  const userDocRef = doc(getFirebaseFirestore(), "serene_users", uid);


  // Set up the listener for real-time updates
  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      dispatch(sereneUserUpdate(docSnapshot.data()));
    }
  }, (error) => {
    console.log("Error fetching document: ", error);
  });

  return unsubscribe;
}


export default App;
