import { Tooltip, Typography } from "@mui/material"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import noteStyle from "./style/note-card.module.css"
import { NoteDetails } from "../../types";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

export const PendingNote = ({ name = "", title, subtitle = " ", note }:
    {
        name: string
        title: string, image?: any,
        note?: NoteDetails,
        subtitle?: string,
        children?: React.ReactNode;
    }) => {
    const navigate = useNavigate();
    return <div className={noteStyle.container}>
        <Typography variant="h5" fontWeight={"bolder"} noWrap sx={{ paddingRight: "20px" }}>👤 {name}</Typography>
        <Typography variant="body1" color={"grey"} fontSize={12}>{title}</Typography>
        {/* <Typography variant="body1" fontSize={12} color={"grey"}>{loading ? "" : subtitle}</Typography> */}

        <div className={noteStyle.body}>
            <div className={noteStyle.truncate}>
                <Typography variant="body1" fontSize={12}>{note?.serene_notes.conversation_summary}</Typography>
            </div>
        </div>
        <div onClick={() => { navigate(`/note/${note!.id}`, { state: { note: note } }) }}><ActionButton title={"Show"} /></div>
    </div>
}

export const NoPendingNote = () => {
    return <div className={noteStyle.container}>
        <Typography variant="h5" fontWeight={"bolder"}>No Actions</Typography>
        <Typography variant="body2" color={"grey"}>You are all caught up</Typography>
        {/* <Typography variant="body1" fontSize={12} color={"grey"}>{loading ? "" : subtitle}</Typography> */}
        <div className={noteStyle.body}>
            <div className={noteStyle.body_center}>
                <CheckCircleOutlineIcon sx={{ fontSize: 100 }} />
            </div>
        </div>
        <div className={noteStyle.disabled}><ActionButton title={"Show"} /></div>
    </div>
}



export const UploadSession = ({ handleFile }: { handleFile: any }) => {
    const hiddenFileInput = useRef(null);


    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        handleFile(fileUploaded);
    };

    const handleClick = (event: any) => {
        if (hiddenFileInput.current) {
            //@ts-ignore
            hiddenFileInput?.current?.click();
        }
    };

    return <div className={noteStyle.container}>
        <input
            accept="video/mp4,video/x-m4v,video/*"
            type="file"
            onChange={handleChange}
            ref={hiddenFileInput}
            style={{ display: 'none' }} // Make the file input element invisible
        />
        <div className={noteStyle.upload_title}><Typography variant="h5" fontWeight={"bolder"}>Upload</Typography>
            <Tooltip title="You can upload your session recordings to automatically add to your client notes." placement="top" arrow>
                <InfoIcon fontSize="small" />
            </Tooltip>
        </div>

        <Typography variant="body2" color={"grey"}>Add your session recording</Typography>
        <div className={noteStyle.body}>
            <div className={noteStyle.body_center}>
                <AddCircleOutlineIcon sx={{ fontSize: 100 }} />
            </div>
        </div>
        <div onClick={handleClick}><ActionButton title={"Add"} /></div>
    </div>
}

const ActionButton = ({ title }: { title: string }) => {
    return (<div className={noteStyle.action_pill}>{title}</div>)
}