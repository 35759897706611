import { Typography } from '@mui/material';
import { motion, MotionValue } from 'framer-motion';
import * as React from 'react';
import landingpageStyle from "./style/section-one.module.css";

interface Props {
    sectionOneopacity: MotionValue<number>;
}

const SectionOne: React.FC<Props> = ({ sectionOneopacity }) => {
    return (
        <div className={landingpageStyle.section_one}>
            <motion.p className={landingpageStyle.introtext}
                style={{ opacity: sectionOneopacity }}>
                <span className={landingpageStyle.fade_in_initial}>We priortize our physical health.</span>
                <br />
                <span> But what about our <span className={landingpageStyle.fade_in_next}>
                    <span className={landingpageStyle.fade_in_underline}><span className="bold">mental health?</span></span></span> </span>
            </motion.p>
            <br />
            <div className={landingpageStyle.fade_in_subtitle}>
                <Typography variant="h5" color="text.secondary">Let's start taking care of ourselves today<span className="bold">😊</span>
                </Typography>
            </div>
        </div>);
}

export default SectionOne;