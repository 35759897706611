import { useScroll, useTransform } from "framer-motion";
import Header from "../../components/header";
import SectionOne from "./landing-page/section-one";
import SectionTwo from "./landing-page/section-two";
import SectionThree from "./landing-page/section-three";
import SectionFour from "./landing-page/section-four";
import { SectionFive } from "./landing-page/section-five";
import { VybzFooter } from "../../components/footer";


const Serene = ({ }) => {

    return (<div>
        <Header product="serene" />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <VybzFooter />
    </div>)
}

export default Serene;