import { Button, Divider, Typography } from "@mui/material";
import landingpageStyle from "../style/section-three.module.css";
import SereneTranscriber from "../../../assets/SereneTranscriber.png";
import Report from "../../../assets/report.png";
import Insights from "../../../assets/insights.png";
import AppImage from "../../../assets/app.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const SectionThree = () => {
    const navigate = useNavigate();

    return (
        <div className={landingpageStyle.third_section}>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Typography variant="h3" fontWeight={"bolder"} align="left">Why choose us?</Typography>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Typography variant="h5" color="text.secondary" align="left" sx={{ marginLeft: "2px" }}>
                    We are not just another health record system helping you with admin work!
                </Typography>
            </motion.div>

            <div className={landingpageStyle.spacer_vertical} />

            <Preview
                onButtonClick={() => { navigate("/serene-transcriber") }}
                useSpacer={false}
                buttonText="Get Started"
                title="✒️ Transcriber"
                image={SereneTranscriber}
                description={
                    <Typography variant="body1" align="left">
                        <section id="serene-transcriber">
                            <p>Automatically generate therapy notes effortlessly from your online sessions.</p>
                            <br />
                            <br />
                            <ul>
                                <li>✅<strong> 70% of clinicians</strong> agree that they do not like to take manual notes while in session.</li>
                                <li>✅ Serene generates in-depth <strong>BIRP</strong> and <strong>SOAP</strong> notes for you.</li>
                                <li>✅ Supports over <strong>70 languages</strong>.</li>
                                <li>✅<strong> Organizes</strong> and <strong>securely stores</strong> all your notes.</li>
                                <li>✅ Our users save at least <strong>6 hours</strong> weekly by automating note generation.</li>
                            </ul>
                        </section>
                    </Typography>
                }
            />

            <div className={landingpageStyle.spacer_vertical} />
            <Divider variant="middle" component="div" sx={{ borderBottomWidth: 6, borderColor: "white" }} />
            <div className={landingpageStyle.spacer_vertical} />

            <Preview
                onButtonClick={() => { navigate("") }}
                useSpacer={true}
                buttonText="What can I see?"
                title="📊 Rich Insights"
                image={Insights}
                description={
                    <Typography variant="body1" align="left">
                        <section id="serene-transcriber">
                            <p>Gain a deeper understanding of your clients' mental health with Serene's Rich Insights. Our advanced platform analyzes various physiological indicators during your sessions.</p>
                            <br />
                            <br />
                            <ul>
                                <li>✅ Monitor key indicators such as <strong>heart rates and breathing rates</strong> to assess stress and emotional states.</li>
                                <li>✅ Detect subtle changes in <strong>voice tone</strong> that may reveal underlying emotions.</li>
                                <li>✅ Keep track of changes <strong>across sessions</strong> to effectively record their mental health journey.</li>
                            </ul>
                        </section>
                    </Typography>
                }
            />

            <div className={landingpageStyle.spacer_vertical} />
            <Divider variant="middle" component="div" sx={{ borderBottomWidth: 6, borderColor: "white" }} />
            <div className={landingpageStyle.spacer_vertical} />

            <Preview
                onButtonClick={() => { window.location.href = "https://app.vybz.health/" }}
                useSpacer={false}
                buttonText="Check out the Vybz App"
                title="🕐 24 Hour Support"
                image={AppImage}
                description={
                    <Typography variant="body1" align="left">
                        <section id="serene-transcriber">
                            <p>Provide your clients with the <strong>Vybz app</strong>, the ultimate mental health companion, designed to support their journey toward emotional well-being.</p>
                            <br />
                            <br />
                            <ul>
                                <li>✅ Vybz provides <strong>tailored suggestions</strong> based on user data to help them cope with daily challenges.</li>
                                <li>✅ Empower users to understand their emotional patterns using our intuitive <strong>mood tracker</strong>.</li>
                                <li>✅ Encourage consistent self-care with gentle reminders for activities such as <strong>meditation, journaling, and exercise</strong>.</li>
                            </ul>
                        </section>
                    </Typography>
                }
            />

            <div className={landingpageStyle.spacer_vertical} />
            <Divider variant="middle" component="div" sx={{ borderBottomWidth: 6, borderColor: "white" }} />
            <div className={landingpageStyle.spacer_vertical} />

            <Preview
                onButtonClick={() => { }}
                useSpacer={true}
                buttonText="How does it work?"
                title="📑 Weekly Reports"
                image={Report}
                description={
                    <Typography variant="body1" align="left">
                        <section id="serene-transcriber">
                            <p>Serene's Weekly Reports, powered by the Vybz app, give you a detailed view of your clients' mental health, covering key areas such as sleep patterns, stress levels, emotional shifts, and activity levels.</p>
                            <br />
                            <ul>
                                <li>✅ Discover <strong>hidden factors</strong> that may be affecting your clients, enabling <strong>early detection and prevention</strong>.</li>
                                <li>✅ Uncover <strong>recurring patterns</strong> or sudden <strong>anomalies</strong>.</li>
                            </ul>
                        </section>
                    </Typography>
                }
            />

            <div className={landingpageStyle.spacer_vertical} />
            <Typography variant="h4" fontWeight={"bolder"} align="left">And of course all the admin tools ...</Typography>
        </div>
    );
};

interface Props {
    useSpacer: boolean;
    image: string;
    title: string;
    description: any;
    buttonText: string;
    onButtonClick: null | any;
}

const Preview: React.FC<Props> = ({ useSpacer, image, description, title, buttonText, onButtonClick = () => { } }) => {
    return (
        <div className={landingpageStyle.preview_row}>
            {useSpacer ? <div className={landingpageStyle.spacer} /> : null}
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className={landingpageStyle.stress_preview}
                src={image}
            />
            <br />
            <br />
            <br />
            <motion.div className={landingpageStyle.preview_description}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Typography variant="h3" fontWeight="bolder" align="left">{title}</Typography>
                <br />
                <br />
                {description}
                <br />
                <br />
                <Button
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    color="primary"
                    variant="outlined"
                    onClick={onButtonClick}
                    sx={{ borderRadius: 30, padding: 1, minWidth: "200px" }}>
                    {buttonText}
                </Button>
            </motion.div>
        </div>
    );
};

export default SectionThree;
