import { motion } from "framer-motion"
import VybzAppLogo from "../assets/logo.svg"
import headerStyle from "./style/header.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Header = ({ product = "" }) => {
    const navigate = useNavigate();

    return (<motion.div className={headerStyle.header}>
        <img src={VybzAppLogo} className={headerStyle.header_logo} />
        <div className={headerStyle.header_title} >
            <span className={headerStyle.header_title_non_bold}>
                {product == "serene" ? "Serene by ‎" : ""}</span> Vybz{" "}
            <span className={headerStyle.header_title_non_bold}>Health</span>
        </div>
        <div className={headerStyle.spacer} />
        <div className={headerStyle.login} >
            <Button onClick={() => { navigate("/login") }} variant="outlined" size="large" sx={{ borderRadius: "20px", paddingLeft: "30px", paddingRight: "30px" }}> Login</Button>
        </div>

    </motion.div>)
}

export default Header;