import { Typography } from "@mui/material"
import dashboardCardStyle from "./style/dashboard-card.module.css"
export const DashboardCard = ({ title, image, actionTitle, loading, subtitle = " ", children, onActionCLick }:
    {
        title: string, image?: any,
        loading: boolean,
        subtitle?: string,
        actionTitle: string,
        children?: React.ReactNode;
        onActionCLick?: any
    }) => {
    return <div className={dashboardCardStyle.container}>
        {image && <img src={image} className={dashboardCardStyle.img} />}
        <div className={dashboardCardStyle.header} >
            <Typography variant="h5" fontWeight={"bolder"}>{title}</Typography>
            <Typography variant="body2" fontSize={12} color={"grey"}>{loading ? "" : subtitle}</Typography>
        </div>
        <div className={dashboardCardStyle.body}>
            {children}
        </div>
        {!loading && <div onClick={onActionCLick ? onActionCLick : () => { }}><ActionButton title={actionTitle} /></div>}
    </div>
}

const ActionButton = ({ title }: { title: string }) => {
    return (<div className={dashboardCardStyle.action_pill}>{title}</div>)
}