import { Typography } from "@mui/material"
import clientCardStyle from "./style/client-history.module.css"
import { useNavigate } from "react-router-dom";
import { ClientDetails } from "../../types";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import moment from "moment";

/**
 * 
 * @param clientDetails 
 * @returns 
 */
export const ClientCard = ({ clientDetails }:
    {
        clientDetails: ClientDetails
    }) => {
    const navigate = useNavigate();

    return <div className={clientCardStyle.container}>
        <div className={clientCardStyle.card_body}>
            <div className={clientCardStyle.card_image}>
                <AccountCircleIcon sx={{ color: "grey", fontSize: "80px" }} />
                {/* <img src={""} className={clientCardStyle.img} /> */}
            </div>
            <div>
                <Typography variant="h5" fontWeight={"bolder"} noWrap sx={{ paddingRight: "20px" }}>{clientDetails.client_name}</Typography>

                <Typography variant="body1" color={"grey"} fontSize={12}>🕓 Last updated on {moment(clientDetails?.last_update).format("MMM Do")}</Typography>
                <Typography variant="h6" color={"grey"} fontSize={12}>📑 {clientDetails.report_count} {clientDetails.report_count == 1 ? "note" : "notes"}</Typography>
            </div>
            <div className={clientCardStyle.spacer} />
            <div onClick={() => { if (clientDetails.id != "") { navigate(`/client-history/${clientDetails.id}`) } }} className={clientCardStyle.navigation} >
                <NavigateNextIcon sx={{ fontSize: "40px" }} />
            </div>


            {/* <div ><ActionButton title={"View Details"} active={clientDetails.id != ""} /></div> */}
        </div>
    </div>;
}


const ActionButton = ({ title, active }: { title: string, active: boolean }) => {
    return (<div className={clientCardStyle.action_pill} style={{ background: active ? "black" : "grey" }}>{title}</div>)
}