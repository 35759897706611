import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import landingpageStyle from "./style/section-two.module.css";
import { motion } from "framer-motion";
import { useState } from "react";
import app from "../../assets/app.png";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const SectionTwo = () => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async () => {

    };

    return (
        <motion.div className={landingpageStyle.second_section} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }} exit={{ opacity: 0 }}>
            <DialogBox open={open} handleClose={handleClose} setEmail={setEmail} onSubmit={onSubmit} />
            <div className={landingpageStyle.into_container}>
                <motion.img src={app} className={landingpageStyle.app_img} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                    transition={{ duration: 1 }} />
                <div>
                    <div className={landingpageStyle.second_section_title}>Vybz: Your Mental Wellness Companion 🤗</div>
                    <div className={landingpageStyle.second_section_subtitle}>Sometimes taking care of ourselves is more difficult than it should be.
                        Vybz provides you with all the tools you need to track and priortize your wellbeing.</div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Typography variant="h3" align="left">Your <strong>Happiness</strong> is our <strong>Mission</strong></Typography>
            </motion.div>
            <br />
            <br />
            <motion.div className={landingpageStyle.button_container} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Button onClick={handleClickOpen} variant="contained" size="large" sx={{ borderRadius: 30, height: 60, width: 250 }}>Start Your Journey</Button>
            </motion.div>
        </motion.div>)
}

const DialogBox = ({ open, handleClose, setEmail, onSubmit }: { open: boolean, handleClose: any, setEmail: any, onSubmit: any }) => <Dialog
    open={open}
    onClose={handleClose}
    PaperProps={{
        component: 'form',
        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            const db = getFirestore()
            if (email != "") {
                const docRef = await addDoc(collection(db, "invites"), {
                    email: email
                });
            }
            handleClose();
        },
    }}
>
    <DialogTitle>We would love to have you join us ❤️</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Vybz is currently in <strong>private beta</strong>. Please enter your email below to request access and we will get back to you soon. 💌
        </DialogContentText>
        <TextField
            onChange={(value) => { setEmail(value.target.value) }}
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard" />
    </DialogContent>
    <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onSubmit={onSubmit} type="submit">Submit</Button>
    </DialogActions>
</Dialog>;

export default SectionTwo;