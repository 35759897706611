import { configureStore } from '@reduxjs/toolkit'
import userSlice from './slice/user-slice'
import sereneUserSlice from "./slice/serene-user"
import calenderSlice from './slice/calender-slice';

const store = configureStore({
    reducer: {
        user: userSlice,
        sereneUser: sereneUserSlice,
        calender: calenderSlice
    },
})

export default store;
