import { Typography } from "@mui/material"
import { Soap } from "../../types"
import noteSection from "./style/note-section.module.css"

export const SoapNotes = ({ soapNotes }: { soapNotes: Soap | undefined }) => {
    return (<div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Subjective</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{soapNotes?.subjective}</Typography>
            </div>
        </div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Objective</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{soapNotes?.objective}</Typography>
            </div>
        </div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Assessment</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{soapNotes?.assessment}</Typography>
            </div>
        </div>
        <div className={noteSection.section}>
            <div className={noteSection.row}>
                <Typography variant="h5" fontWeight={"bolder"}>Plan</Typography>
            </div>
            <div className={noteSection.section_body}>
                <Typography variant="body1">{soapNotes?.plan}</Typography>
            </div>
        </div>
    </div>)
}