import { Typography } from "@mui/material";
import stressStyles from "./style/stress.module.css"
import React from "react";


const Stress = ({ }) => {
    return (<div className={stressStyles.main}>
        <Typography variant="h3" fontWeight="bold">
            Are you feeling Stressed?
        </Typography>
        <Typography variant="h6" fontWeight="normal" color={"grey"}>
            Vybz can easily track and help manage stress. 👍
        </Typography>
        <br />
        <br />
        <Typography variant="h4" fontWeight="normal">
            Tracking your stress 🤒
        </Typography>
        <Typography variant="body1">
            <br />
            Stress can be effectively tracked by monitoring <strong>physiological indicators</strong> such as <strong>heart rate</strong> and <strong>breathing rates</strong>.
            These indicators are directly influenced by the body's stress response, with heart rate often increasing and breathing becoming more rapid or shallow under stress.
            Advances in <strong>wearable technology</strong> and <strong>smartphones</strong> have made it possible to continuously monitor these parameters, providing <strong>real-time insights</strong> into an individual's stress levels.
            By analyzing patterns in <strong>heart rate variability</strong> and <strong>respiration</strong>, these devices can detect signs of stress and alert users, enabling them to take proactive steps to manage their stress and maintain overall well-being.
            This continuous monitoring can also help in identifying long-term stress trends, allowing for better <strong>stress management strategies</strong> and improved mental health outcomes.
        </Typography>
        <div>

        </div>

    </div>);
}

export default Stress;