import { Footer } from "flowbite-react"
import VybzAppCircleLogo from "../assets/circleLogo.png"
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";

export const VybzFooter = () => {
    return <Footer container>
        <div className="w-full text-center">
            <div className="w-full justify-between sm:flex sm:items-center sm:justify-between">
                <Footer.Brand
                    href="#"
                    src={VybzAppCircleLogo}
                    alt="Vybz Logo"
                    name="Vybz Health"
                />
                <Footer.LinkGroup>
                    <Footer.Link href="/login">Login</Footer.Link>
                    <Footer.Link href="https://serene.vybz.health">Serene</Footer.Link>
                    <Footer.Link href="https://app.vybz.health">App</Footer.Link>
                    <Footer.Link href="/privacy">Privacy Policy</Footer.Link>
                    <Footer.Link href="/contact">Contact</Footer.Link>

                </Footer.LinkGroup>
            </div>
            <Footer.Divider />
            <div className="w-full sm:flex sm:items-center sm:justify-between">
                <Footer.Copyright href="#" by="Vybz Health" year={2024} />
                <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                    <Footer.Icon href="https://www.instagram.com/vybzhealth/" icon={BsInstagram} />
                    <Footer.Icon href="https://www.linkedin.com/company/104071148/" icon={BsLinkedin} />
                </div>
            </div>
        </div>
    </Footer >
}