import { createSlice } from '@reduxjs/toolkit'

/**
 * Serene user data
 */
export const sereneUserSlice = createSlice({
    name: 'serene_user',
    initialState: {
        fetched: false,
        id: "",
        display_name: "",
        email: "",
        calenderConnected: false,
        calenderToken: "",
        calenderRefreshToken: "",
    },
    reducers: {
        updateUser: (state, action) => {
            state.fetched = true
            state.display_name = action.payload["display_name"] ?? ""
            state.id = action.payload["id"] ?? ""
            state.email = action.payload["email"] ?? ""
            state.calenderConnected = action.payload["calender_connected"] ?? false
            state.calenderToken = action.payload["calender_token"] ?? ""
            state.calenderRefreshToken = action.payload["calender_refresh_token"] ?? ""
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateUser, } = sereneUserSlice.actions

export default sereneUserSlice.reducer

