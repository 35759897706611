import { useSelector } from "react-redux"
import { AppState } from "../../../types"
import calenderStyle from "../style/calender.module.css"
import { Typography } from "@mui/material"
import { connectCalender, getInvitees } from "../../../helper/calendly-helper"
import { Clipboard } from "flowbite-react"
import moment from "moment"


export const Calender = ({ }) => {

    const sereneUser = useSelector((state: AppState) => state.sereneUser)
    const calender = useSelector((state: AppState) => state.calender)

    // filter out events scheduled for today
    const eventsToday = calender.events.filter((data) => {
        const currentDay = moment().format("DD-YY-MMMM")
        const calenderDate = moment(data.start_time).format("DD-YY-MMMM")
        return currentDay == calenderDate
    })

    // filter out events which are not scheduled for today
    const eventsLater = calender.events.filter((data) => {
        const currentDay = moment().format("DD-YY-MMMM")
        const calenderDate = moment(data.start_time).format("DD-YY-MMMM")
        return currentDay != calenderDate
    })


    return (
        <div>
            <Typography variant="h4">Scheduling Link</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>Share your personalized link for easy scheduling</Typography>
            <div className={calenderStyle.item_container}>
                {sereneUser.calenderConnected ?
                    <Typography variant="body1" >🔗 <a href={calender.userSchedulingLink} className={calenderStyle.link}><span>{calender.userSchedulingLink}  </span></a></Typography> :
                    <Typography variant="body1"  >🔗 <span className={calenderStyle.link_connect} onClick={connectCalender}>Click here to connect your calender and get your personalized link</span></Typography>}
                {sereneUser.calenderConnected && <div className={calenderStyle.clipboard}><Clipboard.WithIconText valueToCopy={calender.userSchedulingLink} /></div>}
            </div>
            <br />
            <Typography variant="h4">Today</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>View all your scheduled sessions for today</Typography>
            {eventsToday.length == 0 && <div className={calenderStyle.item_container}>
                <Typography variant="body1" color={"grey"} >You have a free schedule today!</Typography>
            </div>}
            {eventsToday.map((event) => {
                console.log(JSON.stringify(event))
                return <div className={calenderStyle.item_container}>
                    <div>
                        <Typography variant="body1">🕐 {moment(event.start_time).format("hh:mm A")}</Typography>
                    </div>
                </div>
            })}
            <br />
            <Typography variant="h4">Upcoming</Typography>
            <Typography variant="body1" color={"grey"} fontSize={14}>Stay on top of your upcoming appointments and plan ahead</Typography>
            {eventsLater.length == 0 && <div className={calenderStyle.item_container}>
                <Typography variant="body1" color={"grey"} >No upcoming meetings!</Typography>
            </div>}
            {eventsLater.map((event) => {
                return <div className={calenderStyle.item_container}></div>
            })}
            <br />
            <br />
        </div>
    )
}