import { createSlice } from '@reduxjs/toolkit'
import { CalenderEvent } from '../types';

/**
 * Vybz user data
 */
export const calenderSlice = createSlice({
    name: 'calender',
    initialState: {
        fetched: false,
        userSchedulingLink: "",
        events: [] as CalenderEvent[],
        user_url: "",
    },
    reducers: {
        updateCalenderData: (state, action) => {
            state.fetched = true;
            state.userSchedulingLink = action.payload?.user?.scheduling_url ?? ""
            state.events = action.payload["events"] ?? []
        },
    },
})

// Action creators are generated for each case reducer function
export const { updateCalenderData } = calenderSlice.actions

export default calenderSlice.reducer

