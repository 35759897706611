import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import dahsboardStyle from "./style/dashboard.module.css"
import HomeLogo from "@mui/icons-material/Home";
import logo from "../../assets/logo.svg";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsIcon from '@mui/icons-material/Settings';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useEffect, useState } from 'react';
import { Home } from './home';
import { Notes } from './notes';
import { Settings } from './settings';
import { collection, query, where, orderBy, limit, startAfter, getDocs, getCountFromServer } from 'firebase/firestore';
import { SERENE_AUDIO, SERENE_CLIENT_RECORDS, SERENE_NOTES, SERENE_USERS, SERENE_VIDEOS, getFirebaseFirestore, getFirebaseStorage } from '../../const';
import { AppState, ClientDetails, NoteDetails } from '../../types';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProtectedRoute } from '../../components/protected-route';
import { getStorage, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Calender } from './calender';
import { Modal } from 'flowbite-react';
import { CircularProgress, Typography } from '@mui/material';

export const SereneDashboard = ({ }) => {
    const user = useSelector((state: AppState) => state.sereneUser)
    const calender = useSelector((state: AppState) => state.calender)
    const db = getFirebaseFirestore();
    const storage = getFirebaseStorage();

    const [selectedIndex, setSelectedIndex] = useState(0)
    const [notesLoading, setNotesLoading] = useState(true)
    const [pendingLoading, setPendingLoading] = useState(true)
    const [lastNote, setLastNote] = useState<any>(null);
    const [actionItems, setActionItems] = useState(0);

    // client details
    const [clientRecords, setClientRecords] = useState<ClientDetails[]>([])
    const [lastRecord, setLastRecord] = useState<any>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [clientsLoading, setClientsLoading] = useState<boolean>(true);

    // pending transcription states 
    const [pendingNotes, setPendingNotes] = useState<NoteDetails[]>([])
    const [loading, setLoading] = useState<boolean>(false);

    // upload task state
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [openModal, setOpenModal] = useState(false);

    // in progress states
    const [inProgressCount, setInProgressCount] = useState<number>(0)

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Retrieve the hash from the URL
        const currentHash = location.hash;

        // Perform actions based on the hash value
        if ((currentHash === '#home' || currentHash === "") && selectedIndex != 0) {
            setSelectedIndex(0)
        } else if (currentHash === '#notes' && selectedIndex != 1) {
            setSelectedIndex(1)
        }
        else if (currentHash === '#settings' && selectedIndex != 1) {
            setSelectedIndex(2)
        }
        // Add more conditions as needed
    }, [location]);


    //load the notes after auth succeeds
    useEffect(() => {
        // handle if user has not been created



        if (user.fetched) {
            fetchLastNote()
            fetchPendingItems()
            fetchClientHistory()
            fetchPendingTranscriptions()

            // poll for in progress items
            fetchInProgressItems()
            const stopInterval = setInterval(() => {
                fetchInProgressItems()
            }, (1000 * 60))

            return () => {
                clearInterval(stopInterval)
            }
        }
    }, [user])


    /**
     * Upload file data
     * @param file 
     */
    const uploadFile = ((file: File) => {

        let storageRef;

        if (file) {
            setOpenModal(true)
        }

        if (file.type.includes("video")) {
            storageRef = ref(storage, `${SERENE_VIDEOS}/${user.id}/${file.name}`)

        } else {
            storageRef = ref(storage, `${SERENE_AUDIO}/${user.id}/${file.name}`)
            // audio
        }


        // 'file' comes from the Blob or File API
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                setUploadProgress(progress)

                if (progress >= 100) {
                    setTimeout(() => {
                        setOpenModal(false)
                        setUploadProgress(0)
                    }, 2000)
                }
            },
            (error) => {
                // Handle unsuccessful uploads
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                // getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                //     console.log('File available at', downloadURL);
                // });
            })
    })




    /**
     * Fetch the transcriptions in descending order for notes page
     * @returns 
     */
    const fetchPendingTranscriptions = async (): Promise<void> => {
        if (loading) return;

        const db = getFirebaseFirestore()
        setLoading(true);

        const transcriptionsRef = collection(db, SERENE_NOTES);
        let q = query(
            transcriptionsRef,
            where('user_id', '==', user?.id),
            where('pending', '==', true),
            orderBy('timestamp', 'desc'),
        );

        const querySnapshot = await getDocs(q);
        const docs = querySnapshot.docs.map((doc) => { return { ...doc.data(), id: doc.id } as NoteDetails });
        setPendingNotes((prevNotes) => {
            // Filter out notes with duplicate ids
            const uniqueNotes = docs.filter(newNote =>
                !prevNotes.some(note => note.id === newNote.id)
            );

            // Add only the unique notes to the state
            return [...prevNotes, ...uniqueNotes];
        });
        setLoading(false);
    };

    /**
     * Fetch the count of all the pending notes
     */
    const fetchInProgressItems = async () => {
        const transcriptionRef = collection(db, SERENE_VIDEOS)
        const q = query(transcriptionRef, where("user_id", "==", user.id ?? ""),
            where("status", "==", "progress"))
        const snapshot = await getCountFromServer(q)
        setInProgressCount(snapshot.data().count)
        setPendingLoading(false)
    }



    /**
     * Fetch the count of all the pending notes
     */
    const fetchPendingItems = async () => {
        const transcriptionRef = collection(db, SERENE_NOTES)
        const q = query(transcriptionRef, where("user_id", "==", user.id ?? ""),
            where("pending", "==", true))
        const snapshot = await getCountFromServer(q)
        setActionItems(snapshot.data().count)
        setPendingLoading(false)
    }


    /**
     * Fetch the last note for home page
     */
    const fetchLastNote = async () => {
        const transcriptionRef = collection(db, SERENE_NOTES)
        const q = query(transcriptionRef, where("user_id", "==", user.id ?? ""), orderBy("timestamp", "desc"), limit(1))
        const snapshot = await getDocs(q)
        if (snapshot.docs.length > 0) {
            const lastNote = snapshot.docs[0].data()
            setLastNote(lastNote)
        }
        setNotesLoading(false)
    }

    /**
     * Fetch the client history
     */
    const fetchClientHistory = async () => {
        // fetch the data of clients in descending order sorted by last updated data
        const clientRecordRef = collection(db, SERENE_USERS, user.id ?? "", SERENE_CLIENT_RECORDS)
        const q = query(clientRecordRef, orderBy("last_update", "desc"), limit(10))
        const snapshot = await getDocs(q)
        const clientRecords = snapshot.docs.map((doc) => { return { ...doc.data(), id: doc.id } as ClientDetails })

        setClientRecords((prevRecords) => {
            // Filter out notes with duplicate ids
            const uniqueRecords = clientRecords.filter(newRecord =>
                !prevRecords.some(note => note.id === newRecord.id)
            );

            // Add only the unique notes to the state
            return [...uniqueRecords, ...prevRecords,];
        });
        setClientsLoading(false);
    }


    /**
     * Render title based on the inner route
     * @returns 
     */
    const rendertitle = () => {
        switch (selectedIndex) {
            case 0:
                return "👋 Hello!";
            case 1:
                return "📑 My Notes";
            case 2:
                return "📅 My Calender";
            default:
                return "👋 Hello!";
        }
    }

    /**
     * Render elements based on the inner route
     * @returns 
     */
    const renderSwitch = () => {
        switch (selectedIndex) {
            case 0:
                return <Home onViewNotes={() => setSelectedIndex(1)}
                    onShowCalender={() => setSelectedIndex(2)}
                    notesLoading={notesLoading}
                    lastNote={lastNote}
                    pendingLoading={pendingLoading}
                    actionItems={actionItems}
                    inProgress={inProgressCount}
                    calenderLoading={!calender.fetched}
                    upcomingEvents={calender.events}
                    calenderConnected={user.calenderConnected} />;
            case 1:
                return <Notes pendingNotes={pendingNotes} clientRecords={clientRecords}
                    handleFileUpload={(file: any) => { uploadFile(file) }} />;
            case 2:
                return <Calender />;
        }
    }


    return (
        <ProtectedRoute>
            <div className={dahsboardStyle.container}>
                <Modal show={openModal} onClose={() => setOpenModal(false)}>
                    <Modal.Header>
                        <Typography variant='h5'>Upload In Progress</Typography>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={dahsboardStyle.center_progress}>
                            <CircularProgress value={uploadProgress} size={50} thickness={4} variant='determinate' />
                        </div>
                    </Modal.Body>
                </Modal>
                <div>
                    <Sidebar backgroundColor='#28282B' style={{ height: "100vh" }} collapsed transitionDuration={800} collapsedWidth='100px'>
                        {/* <div style={{ height: "100px" }}></div> */}
                        <div className={dahsboardStyle.sidebar_spacer} />
                        <img src={logo} className={dahsboardStyle.logo} />
                        <Menu

                            menuItemStyles={{
                                button: {
                                    '&:hover': {
                                        backgroundColor: "transparent!important",
                                    }
                                },
                                icon: ({ level, active, disabled }) => {
                                    // only apply styles on first level elements of the tree
                                    return {
                                        color: "white",
                                        backgroundColor: active ? "rgba(225, 225, 225, 0.1)" : "transparent",
                                        borderRadius: "10px",
                                        padding: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        '&:hover': {
                                            backgroundColor: "rgba(225, 225, 225, 0.1)",
                                        }
                                    };
                                },
                            }}>
                            <MenuItem active={selectedIndex == 0} icon={<HomeLogo />} onClick={() => {
                                setSelectedIndex(0)
                                navigate("#home")
                            }} />
                            <br />
                            <MenuItem active={selectedIndex == 1} icon={<TextSnippetIcon />} onClick={() => {
                                setSelectedIndex(1)
                                navigate("#notes")
                            }} />
                            <br />
                            {/* <MenuItem active={selectedIndex == 2} icon={<CalendarTodayIcon />} onClick={() => {
                                setSelectedIndex(2)
                                navigate("#calender")
                            }} /> */}
                        </Menu>
                    </Sidebar>
                </div>
                <div className={dahsboardStyle.body}>
                    <div className={dahsboardStyle.header}>
                        <div className={dahsboardStyle.greeting}>{rendertitle()}</div>
                        <div onClick={() => { navigate("/profile") }}>
                            <AccountCircleIcon sx={{ color: "grey", fontSize: "50px", cursor: "pointer" }} />
                        </div>
                    </div>
                    <div className={dahsboardStyle.page_content}>
                        {renderSwitch()}
                    </div>
                </div>
            </div>
        </ProtectedRoute>);
}
