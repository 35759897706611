import { Button, Typography } from "@mui/material";
import landingpageStyle from "../style/section-two.module.css";
import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SectionTwo = () => {

    const navigate = useNavigate()

    return (
        <motion.div className={landingpageStyle.second_section} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }} exit={{ opacity: 0 }}>

            <div className={landingpageStyle.into_container}>
                <div>
                    <div className={landingpageStyle.second_section_title}>What is Serene? 🦋</div>
                    <br />
                    <div className={landingpageStyle.second_section_subtitle}>Serene is an advanced platform designed for mental health clinicians, offering a <strong>suite of tools</strong> that streamline <strong>administrative tasks</strong> and enhance the depth of client sessions. Serene empowers clinicians to gain meaningful insights, ensuring that they can provide the best possible care while efficiently managing their practice.</div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}>
                        <Typography variant="h3" align="left">We provide the <strong>Best Tech</strong> so that you <strong>can provide the Best Care ♥️</strong></Typography>
                    </motion.div>
                    <br />
                    <br />
                </div>
            </div>
            <br />
            <motion.div className={landingpageStyle.button_container} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}>
                <Button onClick={() => { navigate("/login") }} variant="contained" size="large" sx={{ borderRadius: 30, height: 60, width: 300 }}>Create Your Free Account</Button>
            </motion.div>
        </motion.div>)
}

export default SectionTwo;