import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { ChartData, ChartOptions } from 'chart.js/auto';
import moment from 'moment';

// Register necessary components for ChartJS
//@ts-ignore
ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

// LineChart component
const BdiProgress: React.FC<{ data: { time: number, value: number }[] }> = ({ data }) => {
    // Prepare the chart data with formatted timestamps
    const chartData: ChartData<'line'> = {
        labels: data.map(item => item.time), // Time stamps are passed as x-axis labels
        datasets: [
            {
                // label: 'Value over Time',
                data: data.map(item => item.value), // Values for the line plot
                borderColor: 'rgba(75,192,192,1)', // Line color
                backgroundColor: 'rgba(75,192,192,0.4)', // Area under the line
                pointBackgroundColor: 'rgba(75,192,192,1)', // Points on the line
            },
        ],
    };

    // Chart options with time formatting for x-axis
    const options: ChartOptions<'line'> = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => `Value: ${tooltipItem.raw}`,
                },
            },
        },
        scales: {
            x: {
                type: 'time', // X-axis is a time axis
                time: {
                    unit: 'day', // You can set this to 'minute', 'hour', etc. depending on the granularity of your data
                    // tooltipFormat: 'PPpp', // Format in tooltip (e.g., Jan 1, 2024, 12:00 PM)
                },
                ticks: {
                    callback: function (value, index, values) {
                        return moment(value).format("MMM Do"); // Format tick labels (e.g., Jan 1, 2024)
                    },
                    maxRotation: 0, // Disable rotation for x-axis labels
                    autoSkip: true,  // Skip ticks when necessary to prevent label overlap
                },
                grid: {
                    display: false, // Hide grid lines for x-axis
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Show grid lines for y-axis
                },
            },
        },
    };

    return <Line data={chartData} options={options} height={80} />;
};

export default BdiProgress;
