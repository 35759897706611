import { Typography } from "@mui/material"
import sereneProfileStyle from "./style/serene-profile.module.css"
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { useNavigate } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';

export const SereneProfile = () => {
    const navigate = useNavigate()

    return <div className={sereneProfileStyle.container}>
        <div className={sereneProfileStyle.body}>
            <div className={sereneProfileStyle.header}>
                <div>
                    <div className={sereneProfileStyle.title}><SwitchAccountIcon sx={{ fontSize: "50px", color: "black" }} />  <Typography variant="h3" fontWeight={"bolder"}>Profile</Typography></div>
                </div>
                <div onClick={() => navigate(-1)}>
                    <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                </div>
            </div>
        </div>
    </div>

}