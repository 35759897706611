import { doc, updateDoc } from "firebase/firestore";
import { CALENDLY_CLIENT_ID, CALENDLY_SECRET, getFirebaseFirestore, SERENE_USERS } from "../const";
import axios from 'axios';
import moment from "moment";
import { CalenderEvent, CalendlyEventResponse } from "../types";

let refreshCount = 0

/**
 * 
 * @param accessToken 
 * @returns 
 */
const getCalendlyUser = async (accessToken: string) => {
    try {
        const response = await axios.get('https://api.calendly.com/users/me', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            }
        });

        const data = response.data["resource"]
        return data;
    } catch (error) {
        console.error(error)
    }
}

// Replace with the user's actual Calendly credentials
//let accessToken = 'USER_ACCESS_TOKEN_HERE';
// Function to refresh the access token
export const refreshAccessToken = async (refreshToken: string, userId: string) => {
    try {
        refreshCount = refreshCount + 1;
        const response = await axios.post('https://auth.calendly.com/oauth/token', {
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: CALENDLY_CLIENT_ID,
            client_secret: CALENDLY_SECRET
        });

        const accessToken = response.data.access_token;
        refreshToken = response.data.refresh_token;

        const docRef = doc(getFirebaseFirestore(), SERENE_USERS, userId);
        await updateDoc(docRef, {
            "calender_token": accessToken,
            "calender_refresh_token": refreshToken
        })

        // reset refresh count if successful
        refreshCount = 0;

        return {
            access: accessToken,
            refresh: refreshToken
        };
    } catch (error) {
        //@ts-ignore
        console.error('Error refreshing access token:', error.response ? error.response.data : error.message);
        throw error;
    }
}

// Fetch upcoming events for the user
const fetchUserUpcomingEvents = async (accessToken: string, calendlyUserId: string): Promise<CalendlyEventResponse[]> => {
    try {
        const response = await axios.get('https://api.calendly.com/scheduled_events', {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            params: {
                "user": calendlyUserId, // 'me' refers to the authenticated user
                "status": "active",
                "sort": "start_time:asc",
                "count": 100,
                "min_start_time": moment().utc().toString()
            }
        });

        const events = response.data.collection;
        // fetch the invitees of the events
        return events;
    } catch (error) {
        //@ts-ignore
        return []
    }
}

// Fetch upcoming events for the user
export const getInvitees = async (accessToken: string, eventId: string): Promise<[]> => {
    try {

        const response = await axios.get(`https://api.calendly.com/scheduled_events/${eventId}/invitees`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
        });

        const events = response.data.collection;
        return events;
    } catch (error) {
        //@ts-ignore
        return []
    }
}


export const getCalenderData = async (accessToken: string) => {
    const user = await getCalendlyUser(accessToken);
    const calendlyUserId = user["uri"]
    const events = await fetchUserUpcomingEvents(accessToken, calendlyUserId)

    // const calenderEventData = await Promise.all(events.map(async (data) => {
    //     const parts = data.uri.split("/")
    //     const id = parts[parts.length - 1]
    //     const inviteeData = await getInvitees(accessToken, id)
    //     return {
    //         ...data,
    //         invitees: inviteeData

    //     }
    // }))

    return {
        user,
        events
    }
}


export const connectCalender = () => {
    const clientId = CALENDLY_CLIENT_ID; // Replace with your Calendly Client ID
    const redirectUri = `${window.location.origin}/calendly-callback`; // The URI where Calendly will redirect after authorization
    const responseType = 'code'; // OAuth response type

    const authorizationUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(redirectUri)}`;
    window.open(authorizationUrl, '_blank')?.focus()
}