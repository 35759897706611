import { useEffect, useState } from "react";
import { AppState, ClientDetails, NoteDetails } from "../../types";
import { doc, getDoc, collection, query, orderBy, getDocs } from "firebase/firestore";
import { getFirebaseFirestore } from "../../const";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CancelIcon from '@mui/icons-material/Cancel';
import clientHistoryStyle from "./style/client-history.module.css"
import moment from "moment";
import { ProtectedRoute } from "../../components/protected-route";
import BdiProgress from "./bid-progress";

export const ClientHistory = ({ }) => {

    const params = useParams()
    const user = useSelector((state: AppState) => state.user)
    const [clientDetails, setClientDetails] = useState<ClientDetails | null>(null);
    const [notes, setNotes] = useState<NoteDetails[]>([]);
    const [loading, setLoading] = useState(true);
    const db = getFirebaseFirestore()
    const clientId = params["clientid"];
    const navigate = useNavigate()


    useEffect(() => {
        if (user == null || !user.fetched) {
            return;
        }

        const fetchClientDetailsAndNotes = async () => {
            setLoading(true);
            try {
                // Fetch client details
                const clientDocRef = doc(db, `/serene_users/${user.id}/client_records/${clientId}`);
                const clientSnapshot = await getDoc(clientDocRef);
                if (clientSnapshot.exists()) {
                    setClientDetails(clientSnapshot.data() as ClientDetails);
                }

                // Fetch notes associated with the client, sorted by timestamp
                const notesCollectionRef = collection(db, `/serene_users/${user.id}/client_records/${clientId}/client_notes`);
                const notesQuery = query(notesCollectionRef, orderBy('timestamp', 'desc'));
                const notesSnapshot = await getDocs(notesQuery);

                const notesList: NoteDetails[] = notesSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                })) as NoteDetails[];

                setNotes(notesList);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching client details or notes: ", error);
            }
        };

        fetchClientDetailsAndNotes();
    }, [user, clientId]);

    const bdi = notes.map((n, i) => {
        return {
            time: n.timestamp,
            value: Object.values(n?.bdi_score ?? { total: 0 }).reduce((p, c) => p + c, 0)
        }
    })

    return (
        <ProtectedRoute>
            {!loading ? <div className={clientHistoryStyle.container}>
                {clientDetails && (
                    <div className={clientHistoryStyle.client_details}>
                        <div className={clientHistoryStyle.header}>
                            <Typography variant="h3" fontWeight={"bolder"}>👤 {clientDetails.client_name}</Typography>
                            <div onClick={() => navigate(-1)}>
                                <CancelIcon fontSize="large" sx={{ cursor: "pointer", fontSize: "45px" }} />
                            </div>
                        </div>
                        <br />
                        <Typography variant="body1" color={"grey"}>Created on 🕓 {moment(clientDetails.creation_timestamp || 0).format("MMM Do hh:mm A")}</Typography>
                        <Typography variant="body1" color={"grey"}>Last Updated on 🕓 {moment(clientDetails.last_update || 0).format("MMM Do hh:mm A")}</Typography>
                    </div>
                )}

                <div className={clientHistoryStyle.client_notes}>
                    <Typography variant="h4" fontWeight={"bolder"}>📈 Progress</Typography>
                    <br />
                    <div className={clientHistoryStyle.chart_container}>
                        <Typography variant="body2">Beck Depression Inventory Scores</Typography>
                        <br />
                        <BdiProgress data={bdi} />
                    </div>
                </div>

                {/* Bottom part: List of Notes */}
                <div className={clientHistoryStyle.client_notes}>
                    <Typography variant="h4" fontWeight={"bolder"}>📑 Records ({notes.length})</Typography>
                    <br />
                    {notes.length > 0 ? (
                        <ul style={{ listStyleType: 'none', padding: 0 }}>
                            {notes.map((note) => (
                                <li key={note.id} className={clientHistoryStyle.list_item} onClick={() => { navigate(`/note/${note?.id}`) }}>
                                    <div className={clientHistoryStyle.list_body}>
                                        <div>
                                            <p>🕓 {moment(note.timestamp).format("MMM Do hh:mm A")}</p>
                                            <div className={note.pending ? clientHistoryStyle.pending : clientHistoryStyle.approved}>
                                                {note.pending ? "Pending" : "Approved"}
                                            </div>
                                        </div>
                                        <NavigateNextIcon sx={{ fontSize: "40px" }} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No notes are available</p>
                    )}
                </div>
                <br />
                <br />
            </div> : <div className={clientHistoryStyle.container_loading}><CircularProgress /></div>}
        </ProtectedRoute>
    );
}