import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material";
import landingpageStyle from "../style/section-four.module.css";
import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SectionFour = () => {

    const navigate = useNavigate()

    return (
        <motion.div className={landingpageStyle.section} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }} exit={{ opacity: 0 }}>


            <div className={landingpageStyle.into_container}>
                <div>
                    <div className={landingpageStyle.section_title}>Easily manage your business</div>
                    <div className={landingpageStyle.section_subtitle}>With tools helping you handle every aspect of your business</div>
                </div>
            </div>
            <br />
            <br />
            <div className={landingpageStyle.feature_section}>
                <Typography variant="h3">Scheduling ✅ </Typography>
                <br />
                <br />
                <Typography variant="h5">Say goodbye to the hassle of manually scheduling appointments. With Serene, your scheduling needs are seamlessly managed through integration with Calendly. The platform provides you with a personalized scheduling link, making it effortless for your clients to book sessions at their convenience. No more back-and-forth emails or missed appointments—Serene automatically handles it all, giving you more time to focus on what truly matters.</Typography>
            </div>
            <br />
            <br />
            <div className={landingpageStyle.feature_section}>
                <Typography variant="h3">Payments ✅ </Typography>
                <br />
                <br />
                <Typography variant="h5">Managing payments can be complex, but with Serene, it’s a breeze. The platform handles all aspects of payment processing, including applying discounts, managing different rates for various services, and processing refunds. You don’t have to stress about financial details—Serene takes care of it all, allowing you to concentrate on delivering exceptional care.</Typography>
            </div>
            <br />
            <br />
            <div className={landingpageStyle.feature_section}>
                <Typography variant="h3">Assessments ✅ </Typography>
                <br />
                <br />
                <Typography variant="h5">Understanding your clients' needs has never been easier. Serene allows you to exchange secure assessment documents with your clients, ensuring that you have all the information you need to provide the best care. The platform also takes care of reminding clients to complete their assessments, so you can be confident that everything is on track without the need for constant follow-ups. ✅ </Typography>
            </div>
            <br />
            <br />
            <div className={landingpageStyle.feature_section}>
                <Typography variant="h3">Security ✅ </Typography>
                <br />
                <br />
                <Typography variant="h5">At Serene, we prioritize your privacy and security above all else. Our platform uses state-of-the-art encryption and industry-standard security measures to protect your sensitive information. Rest assured that your data—and your clients' data—is always safe with Serene, so you can focus on your practice without worry.</Typography>
            </div>
            <br />
            <br />
            <div className={landingpageStyle.feature_section}>
                <Typography variant="h3">Always Here for You ✅</Typography>
                <br />
                <br />
                <Typography variant="h5">
                    Need new features or facing any issues? We've got you covered. At Serene, our dedicated team and advanced AI are committed to providing you with the support and tools you need to succeed. We're always here to ensure you have the best possible experience.
                </Typography>
            </div>
        </motion.div>)
}

export default SectionFour;