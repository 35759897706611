import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography } from '@mui/material';
import { CALENDLY_CLIENT_ID, CALENDLY_SECRET } from '../../const';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { AppState } from '../../types';

export const CalendlyCallback = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const user = useSelector((state: AppState) => state.user)

    useEffect(() => {
        const exchangeAuthCodeForToken = async () => {
            const params = new URLSearchParams(window.location.search);
            const authCode = params.get('code');

            if (authCode) {
                try {
                    const response = await axios.post('https://auth.calendly.com/oauth/token', {
                        grant_type: 'authorization_code',
                        client_id: CALENDLY_CLIENT_ID,
                        client_secret: CALENDLY_SECRET,
                        redirect_uri: `${window.location.origin}/calendly-callback`,
                        code: authCode
                    });

                    updateUserData(user.id, response.data)

                    // save the data
                } catch (error) {
                    console.error(error)
                    setError('Failed to connect Calendly. Please try again.');
                } finally {
                    setLoading(false);
                }
            } else {
                setError('Authorization code not found.');
                setLoading(false);
            }
        };
        if (user.fetched) {
            exchangeAuthCodeForToken();
        }
    }, [user]);

    return (
        <Box sx={{ textAlign: 'center', padding: 20 }}>
            {loading ? (
                <Typography variant="h5">🗓️ Connecting to Calendly...</Typography>
            ) : (
                <>
                    {error ? (
                        <Typography variant="h5" color="error">😭 {error}</Typography>
                    ) : (
                        <Typography variant="h5">👌 Successfully connected to Calendly!</Typography>
                    )}
                </>
            )}
        </Box>
    );
};

const automaticallyGenerateEvent = async () => {

}

const updateUserData = async (userId: string, data: any) => {
    const db = getFirestore()
    //const userCollection = collection(db, USER_COLLECTION)
    const docRef = doc(db, "serene_users", userId);
    await updateDoc(docRef, {
        "calender_connected": true,
        "calender_token": data.access_token,
        "calender_refresh_token": data.refresh_token
    })
}
